<template>
  <router-view name="showcase" v-bind:key="$route.path"></router-view>
</template>
<script>
export default {
  name: "Showcase",
};
</script>
<style lang="scss">
.block-move {
  transition: transform 0.5s;
}
</style>